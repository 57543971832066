import './App.css';
import Logo from './Logo/Logo';
import AnyQuestions from './components/AnyQuestions/AnyQuestions';
import CardsSection from './components/CardsSection/CardsSection';
import Description from './components/Description/Description.tsx';
import DetailedInfo from './components/DetailedInfo/DetailedInfo';
import Facing from './components/Facing/Facing';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';
import GetOffer from './components/GetOffet/GetOffer';
import { LanguageProvider } from './components/LanguageContext/LanguageContext';
import AboutUs from './components/MainTextSectionAboutUs/AboutUs';
import Progress from './components/Progress/Progress';
import StockCottages from './components/StockCottages/StockCottages';
import "smoothscroll-for-websites";

function App() {
  return (
    <LanguageProvider>
      <div className="main-container">
        <Facing />
        <Description />
        <CardsSection />
        <AboutUs />
        <Gallery />
        <StockCottages />
        <DetailedInfo />
        {/* <AnyQuestions /> */}
        <GetOffer />
        <Footer />
        <Progress />
      </div>
    </LanguageProvider>
  );
}

export default App;
