import { useContext } from 'react';
import classes from './Footer.module.css';
import { BsInstagram, BsYoutube } from 'react-icons/bs';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa'
import { LanguageContext } from '../LanguageContext/LanguageContext';
import Logo from '../../Logo/Logo';

function Footer() {
    const { language } = useContext(LanguageContext);

    const translations = {
        ru: {
            contacts: "Мы в соцсетях"
        },
        uk: {
            contacts: "Ми у соцмережах"
        },
        en: {
            contacts: "Our socials"
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.holder}>
                <div className={classes.main}>
                    <div className={classes.navContainer}>
                        <div className={classes.logoContainer}>
                            <Logo /> Family Olive Club
                        </div>
                    </div>
                    <div className={classes.contactsHolder}>
                        {/* <span>2023 </span>
                        <span>© All rights reserved.</span> */}
                        <span className={classes.title}>Номер телефона: +89957730505</span>
                        <span className={classes.title}>Почта: bakurianioliveclub@gmail.com</span>
                    </div>
                    <div className={classes.additional}>
                        <span className={classes.title}>{translations[language].contacts}</span>
                        <div className={classes.contactsHolderIcons}>
                            <div className={classes.iconHolder}>
                                <a href="https://www.instagram.com/bio_cottege/" target="_blank">
                                    <BsInstagram className={classes.inst} style={{ fontSize: 23 }} />
                                </a>
                                <a href="https://www.youtube.com/@OliveFamilyClub" target="_blank">
                                    <BsYoutube className={classes.inst} style={{ fontSize: 32, marginTop: 2 }} />
                                </a>
                                <a href="https://t.me/oliveinvestclub" target="_blank">
                                    <FaTelegramPlane className={classes.inst} style={{ fontSize: 25 }} />
                                </a>

                                {/* <FaTwitter className={classes.twitter} style={{ fontSize: 30 }} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;