import React from 'react';
import classes from './Progress.module.css';

function Progress(props) {
    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        const onScroll = e => {
            setWidth(e.target.documentElement.scrollTop / (document.documentElement.scrollHeight - window.innerHeight) * window.innerWidth);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [width]);

    return (
        <div className={classes.slider} style={{width: width}}></ div>
    );
}

export default Progress;