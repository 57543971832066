import { useContext } from 'react';
import Logo from '../../Logo/Logo';
import classes from './Facing.module.css';
import LanguagePicker from './LanguagePicker';
import video from './bg.mp4';
import { LanguageContext } from '../LanguageContext/LanguageContext';

function Facing() {
    const { language } = useContext(LanguageContext);

    const scrollToDetailed = () => {
        const element = document.getElementById('call-me-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const translations = {
        ru: {
            main_title: "ОЛИВКОВОЕ ДЕРЕВО — ЛИКВИДНОСТЬ ВНЕ ВРЕМЕНИ",
            btn: "УЗНАТЬ БОЛЬШЕ"
        },
        en: {
            main_title: "OLIVE TREE - TIMELESS LIQUIDITY",
            btn: "DISCOVER MORE"
        }
    }
    return (
        <div>
            <video className={classes.videoContainer} autoPlay muted loop>
                <source className={classes.video} src={video} type="video/mp4" />
            </video>
            <div className={classes.bgimage}>
                <div className={classes.holderContainer}>
                    <div className={classes.contentContainer}>
                        <div className={classes.navContainer}>
                            <div className={classes.logoContainer}>
                                <Logo /> Family Olive Club
                            </div>
                            <LanguagePicker />
                        </div>
                    </div>
                </div>
                <div className={classes.maintitle}>{translations[language].main_title}</div>
                <div onClick={scrollToDetailed} className={classes.button}>{translations[language].btn}</div>
            </div>
        </div>
    )
}

export default Facing;